.homeContainerParent {

    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10vw;

    .movingObj {
        font-size: 500px;
        width: 100%;
        height: 100%;
        color: blue;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        animation: questionMark 5s infinite;
    }

    .heroContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(30px);
        z-index: 1;
        gap: 10vw;


        .center {
            height: fit-content;
            display: flex;
            justify-content: center;
            flex-direction: column;

            h1 {
                text-align: start;
                font-size: 60px;
                font-weight: 700;
            }

            h2 {
                text-align: start;

                font-size: 45px;
                font-weight: 400;
            }

            .links {
                width: 100%;
                margin-top: 3vh;
                display: flex;
                gap: 10%;
                align-items: center;
            }

        }
    }


}

.phoneContainer {

    overflow: hidden;
}

@media screen and (max-width: 500px) {
    .center {
        width: 80vw;

        h1 {
            word-wrap: break-word;
            font-size: 25px !important;
        }

        h2 {
            font-size: 20px !important;

        }
    }
}

@media screen and (max-width: 855px) {
    .heroContainer {
        flex-direction: column;

    }

    .center {
        width: 80vw;
        align-items: center;
        justify-content: center;

        h1 {
            text-align: center;
            word-wrap: break-word;
            font-size: 25px !important;
        }

        h2 {
            text-align: center;

            font-size: 20px !important;

        }

        .links {
            align-items: center;
            justify-content: center;
        }
    }
}

@keyframes questionMark {

    0% {
        color: blue;
    }

    50% {
        color: rgb(247, 108, 108);
        transform: scale(1.3);
    }

    100% {
        color: blue;
    }

}