.backgroundContainer{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.096);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(4, 29, 165, 0.219);
    backdrop-filter: blur(7px);
    overflow: hidden;
    .container{
        min-width: 50vw;
        min-height: 70vh;
        background-color: rgba(92, 17, 17, 0.24);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        border-radius: 10px;
    }

}