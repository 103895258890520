.container{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 5%;
    border: 1px solid grey ;
    border-radius: 20px;
    margin-bottom: 3vh;
    .imgContainer{
        background-color: black;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
    }
    .createPostContainer{
        width: 90%;
        height: 80%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: stretch;
        border: 1px solid rgba(65, 65, 65, 0.377);
        border-radius: 20px;
        cursor: pointer;
        font-size: 13px;
    }
    }