@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap');

body {
	margin: 0;
	font-family: 'Wix Madefor Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    background-color: #f2f2f2 ;
    
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* variables */
$primary-color: #0d0f7c;
$shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;;
$transition: 0.1s ease;

/* --------- */
.button-primary{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #0d0f7c;
    border-radius: 1rem;
    color: white;
    transition: all $transition ;

    &:hover{
        transform: scale(1.05);
        box-shadow: $shadow;
    }

}
.button-weak{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 1rem;
    color: $primary-color;
    border: 1px solid $primary-color;
    transition: all $transition ;

    &:hover{
        transform: scale(1.1);
        box-shadow: $shadow;
    }

}