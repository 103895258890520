.phoneContainer {
	min-width: 40px;
	position: relative;

	.bgImagePhone{
		width: 99%;
		height:100%;
		position: absolute;
		left: 0;
		top:0;
		display: block;
		border-radius: 50px;
		z-index: 1;
		overflow: hidden;
	}
	.phoneImage{
		width: 100%;
		height: 100%;
		z-index: 2;
		position: relative;
	}
}

@media screen and (max-width: 855px) {
    .phoneContainer{
        flex-direction: column;
        height: 40vh;
    }
}