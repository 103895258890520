.container {
    width: 100%;
    min-height: 30vh;
    margin-bottom: 3vh;
    border-radius: 12px;
    padding: 2%;
    position: relative;
    background-color: white;
    .topSection {
        width: 100%;
        height: 7vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        .topLeft {
            display: flex;
            align-items: center;

            .nameAndDate {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 30%;

                h4 {
                    font-weight: bold;
                    font-size: 18px;
                }

                span {
                    font-size: 13px;
                }
            }


        }
        .topRight{
            font-weight: 900;
            cursor: pointer;
            width: 5%;
        }
        .subMenu{
            position: absolute;
            top:3%;
            right: 9%;
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: fit-content;
            min-width: 100px;
            // transition: all 0.3s ease;
            border: 1px solid black;
            border-radius: 5px;

            .subMenuItem{
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 20px;
                padding: 10px;
                // background-color: white;
                backdrop-filter: blur(3px);
            }
        }
    }
    .postContent{
        margin-top: 3%;

    }
    .bottomSection{
        width: 100%;
        height: 5vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 3%;
        
        cursor: pointer;
        .bottomButton{
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: start;
            gap: 7%;
            align-items: center;
            transition: all 0.1s ease-in;
            padding: 0px 2%;
            .iconContainer{
                height: 80%;
                object-fit: contain;
                display: flex;
                align-items: center;
                img{
                    height: 100%;
                }

            }
            &:hover{
                background-color: rgba(70, 70, 70, 0.226);
                border-radius: 5px;
            }
        }
   
    }

}