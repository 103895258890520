.container{
    max-width: 700px;
    width: 40vw;
    height: fit-content;
    min-height: 100vh;
}

@media screen and (max-width:600px) {
    .container{
        width: 90vw;
    }
    
}